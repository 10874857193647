@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900;1000&family=Varela+Round&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Mulish', sans-serif;
}

:root {
  --theme: #173db9;
}

 p {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.1px;
}

.main_line h2 {
  font-size: 18px;
  font-weight: 400;
  position: relative;
  margin-bottom: 2rem;
}

.main_line h2:before {
  content: "";
  position: absolute;
  width: 15%;
  height: 2px;
  background: linear-gradient(45deg, red, #004eff, green);
  bottom: -77%;
  left: 0%;
}

.btn {
  background: var(--theme) !important;
  color: #fff !important;
  padding: 10px 25px !important;
  border: none !important;
  outline: none !important;
  transition: all 0.4s ease-in-out !important;
  position: relative;
}

.btn:hover {
  background: #eee !important;
  color: var(--theme) !important;
  padding: 10px 25px !important;
  border: none !important;
  outline: none !important;
}

li{
  list-style: none;
}

.animate {
  animation: updown 2s ease-in-out infinite alternate-reverse both;
}

@keyframes updown {
  0% {
    transform: translateY(5%);
  }

  100% {
    transform: translateY(-5%);
  }
}

.navbar .navbar-brand {
  font-size: 30px;
  color: var(--theme);
  font-family: 'Varela Round', sans-serif;
  font-weight: 900;
}

.navbar .navbar-brand img {
  width: 50px;
}

.navbar-nav .nav-link.active {
  color: var(--theme) !important;
 position: relative;
}

.navbar-nav .nav-link.active::before {
  content: '';
  position: absolute;
  background: linear-gradient(45deg, red, #004eff, green);
  bottom: 0;
  display: block;
  width: 100%;
  height: 2px;
  left: 0;
}

.navbar-nav li.nav-item {
  margin-left: 5px;
}

.navbar-nav .nav-item a {
  font-weight: 600;
}

.navbar-brand:focus,
.navbar-brand:hover {
  color: var(--theme) !important;
}

/* font-family: 'Mulish', sans-serif;
font-family: 'Varela Round', sans-serif; */

p, h1, h2, h3, h4, h5, h6{
  font-family: 'Mulish', sans-serif;
}


.navbar-toggler:focus{
  box-shadow: unset !important;
}

.home_sec {
  margin-top: 2rem;
}

.vibrate-span {
  animation-name: wave-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}


@keyframes wave-animation {
  0% {
    transform: rotate(0deg)
  }

  10% {
    transform: rotate(14deg)
  }

  20% {
    transform: rotate(-8deg)
  }

  30% {
    transform: rotate(14deg)
  }

  40% {
    transform: rotate(-4deg)
  }

  50% {
    transform: rotate(10deg)
  }

  60% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(0deg)
  }
}

.home_sec {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_sec .home-right img {
  width: 100%;
}

.home-left p {
  font-size: 18px;
  line-height: 1.7;
  font-weight: 400;
}

.home-left span.arrowAbs {
  transform: translateX(-21px);
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.home-left .btn:hover span.arrowAbs {
  transform: translateX(0px);
  opacity: 1;
  visibility: visible;
}



/* About css  */

.img_myself {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 275px;
  height: 275px;
  /* margin: auto; */
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  animation: morphing 10s infinite;
}


.img_myself:hover{
  animation-play-state: paused;
}

@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
  }
  25% { 
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0,0,0,0.2);
  }
  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
}


.img_myself img {
  width: 100%;
}

.myself ul.name_social {
  display: flex;
  align-items: center;
}

.name_social li a {
  font-size: 18px;
  color: #565656;
}

.name_social li {
  margin-right: 15px;
}

.myself {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.myself h3 {
  padding: 10px 0 5px 0;
  margin-top: 1rem;
}

li.git:hover a {
  color: #0de90d;
}

li.insta:hover a {
  color: #ff4e4e;
}

li.linkdln:hover a {
  color: #004eff;
}

li.mail:hover a {
  color: #00f3ff;
}


.about_right h2 {
  font-size: 18px;
  font-weight: 400;
}

.about_right h2 {
  font-size: 18px;
  font-weight: 400;
  position: relative;
  margin-bottom: 2rem;
}

.about_right h2:before {
  content: "";
  position: absolute;
  width: 15%;
  height: 2px;
  background: linear-gradient(45deg, red, #004eff, green);
  bottom: -77%;
  left: 0%;
}

.about_right p {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.1px;
}

.main-nav.sticky {
  background: #f9f9f9;
  box-shadow: 0px 1px 7px 2px #d9d9d9;
  padding: 8px 0;
  position: sticky;
  top: 0;
  transition: all 0.3s;
  width: 100%;
  overflow: hidden;
  z-index: 9999;
}

.main_aboutSec img {
  width: 100%;
}




/* footer start from here  */

.footer ul.footer_icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_intro {
  text-align: center;
}

.footer_icon li a {
  font-size: 20px;
}

.footer_icon li a {
  font-size: 20px;
  color: black;
}

.footer li {
  margin: 0 10px;
}


/* contact us page  */

.contact__inputs{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.FormsImgSecs img {
  width: 82%;
  height: 380px;
  object-fit: cover;
  border-radius: 12px;
  position: absolute;
  bottom: 0;
}
.contact-form input {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  outline: none;
}

.formWithImaget {
  margin-top: 4rem;
}

.formWithImaget{
  margin-top: 4rem !important;
}

.contact-form textarea {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  outline: none;
}

.contact-form {
  background: #eee;
  padding: 50px 35px;
  border-radius: 15px;
  box-shadow: -5px 7px 4px 0px #173db930;
}
.FormsImgSecs:before {
  content: "";
  background: #173db93d;
  height: 93%;
  width: 93%;
  border-radius: 12px;
  top: 0;
  right: 0;
  position: absolute;
}

.FormsImgSecs {
  position: relative;
}

.UnderLine {
  border-bottom: 2px solid #c1bebe;
}